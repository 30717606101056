import { CausesRepository } from "../../repository/api/cause/cause";
import { actionCreators } from "../../state";

export class CauseUseCases {
    private api: CausesRepository;
    private stateActions: typeof actionCreators;

    constructor(api: CausesRepository, stateActions: typeof actionCreators) {
        this.api = api;
        this.stateActions = stateActions;
    }

    public ReadCauses = async () => {
        this.stateActions.SendCauseListRequest()
        let response = await this.api.ReadCauses()
        if (response instanceof Error) {
            this.stateActions.CauseListError(response.message)
            return
        } else {
            this.stateActions.CauseListSuccess(response)
        }
    }

    public DeleteCause = async (id: number) => {
        this.stateActions.CauseDeleteRequest()
        let response = await this.api.DeleteCause(id)
        if (response instanceof Error) {
            this.stateActions.CauseDeleteError(response.message)
            return
        } else {
            this.stateActions.CauseDeleteSuccess(id)
        }
    }

    public ReadCauseById = async (id: UniqueId) => {
        let response = await this.api.ReadCauseById(id)
        return response
    }

    public ReadCauseForLead = async (leadId: number) => {
        let response = await this.api.ReadCauseForLead(leadId)
        return response
    }

    public CreateCause = async (packetIds: number[], items: string[], callback?: ()=>void) => {
        this.stateActions.CauseCreateRequest()
        let response = await this.api.CreateCause(packetIds, items)
        if (response instanceof Error) {
            this.stateActions.CauseCreateError(response.message)
            return
        } else {
            this.stateActions.CauseCreateSuccess(response)
            if (callback) {
                callback()
            }
        }
    }

    public UpdateCause = async (id: number, packetIds: number[], items: string[], callback?: ()=>void) => {
        this.stateActions.CauseUpdateRequest()
        let response = await this.api.UpdateCause(id, packetIds, items)
        if (response instanceof Error) {
            this.stateActions.CauseUpdateError(response.message)
            return
        } else {
            this.stateActions.CauseUpdateSuccess(response)
            if (callback) {
                callback()
            }
        }
    }

}