import { Decision } from "../../domain/lead/leadEnums";
import { CitysRepository } from "../../repository/api/city/city";
import { ClientsRepository } from "../../repository/api/client/client";
import { LeadsRepository } from "../../repository/api/lead/lead";
import { ManagersRepository } from "../../repository/api/managers/managers";
import { PacketsRepository } from "../../repository/api/packet/packet";
import { actionCreators } from "../../state";

export class LeadUseCases {
    private api: LeadsRepository;
    private cityApi: CitysRepository;
    private managers: ManagersRepository;
    private packets: PacketsRepository;
    private clients: ClientsRepository;
    private stateActions: typeof actionCreators;

    constructor(api: LeadsRepository, cityApi: CitysRepository,  managers: ManagersRepository, packets: PacketsRepository, clients: ClientsRepository, stateActions: typeof actionCreators) {
        this.api = api;
        this.cityApi = cityApi;
        this.managers = managers;
        this.packets = packets;
        this.clients = clients;
        this.stateActions = stateActions;
    }

    public ReadLeads = async (clientId: number, managerId: number, status: string, cityId: number, packet: string, transferDateFrom: Date, transferDateTo: Date, offset: number, limit: number, sorting: string) => {
        this.stateActions.SendLeadListRequest()
        let response = await this.api.ReadLeads(clientId, managerId, status, cityId, packet, transferDateFrom, transferDateTo, offset, limit, sorting)
        if (response instanceof Error) {
            this.stateActions.LeadListError(response.message)
            return
        } else {
            this.stateActions.LeadListSuccess(response)
        }
    }

    public ExportLeads = async (clientId: number, managerId: number, status: string, cityId: number, packet: string, transferDateFrom: Date, transferDateTo: Date, sorting: string) => {
        let response = await this.api.ExportLeads(clientId, managerId, status, cityId, packet, transferDateFrom, transferDateTo, sorting)
        return response
    }

    public ReadLeadById = async (id: number) => {
        this.stateActions.SendLeadListRequest()
        let response = await this.api.ReadLeadById(id)
        return response
    }

    public SetModeratorForLead = async (id: number,  moderatorId: number, callback?: ()=>void) => {
        this.stateActions.SendLeadListRequest()
        let response = await this.api.SetModeratorForLead(id, moderatorId)
        if (response instanceof Error) {
            this.stateActions.LeadListError(response.message)
            return
        } else {
            this.stateActions.LeadUpdateSuccess(response)
            if (callback) {
                callback()
            }
        }
    }

    public ModeratorDecision = async (id: number,  decision: Decision, cause: string, comment: string, callback?: ()=>void) => {
        this.stateActions.SendLeadListRequest()
        let response = await this.api.ModeratorDecision(id, decision, cause, comment)
        if (response instanceof Error) {
            this.stateActions.LeadListError(response.message)
            return
        } else {
            this.stateActions.LeadUpdateSuccess(response)
            if (callback) {
                callback()
            }
        }
    }

    public ClientCause = async (id: number,  cause: string, callback?: ()=>void) => {
        this.stateActions.SendLeadListRequest()
        let response = await this.api.SetClientCause(id, cause)
        if (response instanceof Error) {
            this.stateActions.LeadListError(response.message)
            return
        } else {
            this.stateActions.LeadUpdateSuccess(response)
            if (callback) {
                callback()
            }
        }
    }

    public FinalizeModeration = async (id: number, callback?: ()=>void) => {
        this.stateActions.SendLeadListRequest()
        let response = await this.api.FinalizeModeration(id)
        if (response instanceof Error) {
            this.stateActions.LeadListError(response.message)
            return
        } else {
            this.stateActions.LeadUpdateSuccess(response)
            if (callback) {
                callback()
            }
        }
    }

    public RejectLead = async (id: number, callback?: ()=>void) => {
        this.stateActions.SendLeadListRequest()
        let response = await this.api.RejectLead(id)
        if (response instanceof Error) {
            this.stateActions.LeadListError(response.message)
            return
        } else {
            this.stateActions.LeadUpdateSuccess(response)
            if (callback) {
                callback()
            }
        }
    }

    public ExchangeOnZero = async (id: number, callback?: ()=>void) => {
        this.stateActions.SendLeadListRequest()
        let response = await this.api.ExchangeOnZero(id)
        if (response instanceof Error) {
            this.stateActions.LeadListError(response.message)
            return
        } else {
            this.stateActions.LeadUpdateSuccess(response)
            if (callback) {
                callback()
            }
        }
    }

    public AnnulLead = async (id: number, callback?: ()=>void) => {
        this.stateActions.SendLeadListRequest()
        let response = await this.api.AnnulLead(id)
        if (response instanceof Error) {
            this.stateActions.LeadListError(response.message)
            return
        } else {
            this.stateActions.LeadUpdateSuccess(response)
            if (callback) {
                callback()
            }
        }
    }

    public ReadCityList = async () => {
        let response = await this.cityApi.ReadCitys()
        return response
    }

    public ReadManagersList = async () => {
        let response = await this.managers.ReadManagers()
        return response
    }

    public ReadModeratorsList = async () => {
        let response = await this.managers.ReadModerators()
        return response
    }

    public ReadLeadStatusList = async () => {
        let response = await this.api.ReadLeadStatusList()
        return response
    }

    public ReadPacketList = async () => {
        let response = await this.packets.ReadPackets()
        return response
    }

    public ReadClientList = async () => {
        let response = await this.clients.ReadClients()
        return response
    }

}