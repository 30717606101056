
import { AmoFilesRepository } from "../../repository/api/amofile/amofile";
import { actionCreators } from "../../state";

export class AmofileUseCases {
    private api: AmoFilesRepository;
    private stateActions: typeof actionCreators;

    constructor(api: AmoFilesRepository, stateActions: typeof actionCreators) {
        this.api = api;
        this.stateActions = stateActions;
    }

    public ReadAmofiles = async (offset: number, limit: number, sorting: string) => {
        this.stateActions.SendAmoFileListRequest()
        let response = await this.api.ReadAmoFiles(offset, limit, sorting)
        if (response instanceof Error) {
            this.stateActions.AmoFileListError(response.message)
            return
        } else {
            this.stateActions.AmoFileListSuccess(response)
        }
    }

    public DeleteAmofile = async (id: number) => {
        this.stateActions.AmoFileDeleteRequest()
        let response = await this.api.DeleteAmoFile(id)
        if (response instanceof Error) {
            this.stateActions.AmoFileDeleteError(response.message)
            return
        } else {
            this.stateActions.AmoFileDeleteSuccess(id)
        }
    }

    public MassDeleteAmofiles = async (ids: number[]) => {
        this.stateActions.AmoFileDeleteRequest()
        let response = await this.api.MassDeleteAmoFiles(ids)
        if (response instanceof Error) {
            this.stateActions.AmoFileDeleteError(response.message)
            return
        } else {
            response.forEach((id)=>{
                this.stateActions.AmoFileDeleteSuccess(id)
            })
        }
    }

    

    public CreateAmofile = async (id: number, files: File[], callback?: ()=>void) => {
        this.stateActions.AmoFileCreateRequest()
        let response = await this.api.CreateAmoFile(id, files)
        if (response instanceof Error) {
            this.stateActions.AmoFileCreateError(response.message)
            return
        } else {
            this.stateActions.AmoFileCreateSuccess(response)
            if (callback) {
                callback()
            }
        }
    }

    public CreateAmofileCar = async (phone: string, cityName: string, regionName: string, clientName: string, carId: number, utmSource: string, tag: string, isNew: boolean, file: File) => {
        this.stateActions.AmoFileCreateRequest()
        let response = await this.api.CreateAmoFileCar(phone, cityName, regionName, clientName, carId, utmSource, tag, isNew, file)
        if (response instanceof Error) {
            this.stateActions.AmoFileCreateError(response.message)
            return false
        } else {
            this.stateActions.AmoFileCreateSuccess([response])
            return true
        }
    }

    public CreateAmofileCarXlsx = async (file: File) => {
        let response = await this.api.CreateAmoFileXlsx(file)
        return response
    }

    public ReadCarModels = async () => {
        this.stateActions.AmoFileCreateRequest()
        let response = await this.api.ReadCarModels()
        return response
    }

}