import axios from "axios";

import { Configuration, PacketApi, } from "../generated";
import { Packet } from "../../../domain/packet/packet";


export class PacketsRepository {
    private service: PacketApi;

    constructor() {
        this.service = new PacketApi(new Configuration(), process.env.REACT_APP_BACKEND_URL)
    }
    
    async ReadPackets(): Promise<Packet[] | Error> {
        try {
            let response = await this.service.apiPacketGet({ headers: { "Authorization": localStorage.getItem("token") } })

            if (response.status === 200) {
                let packets: Packet[] = []
                response.data!.list.forEach(packetData => {
                    let packet = new Packet(packetData.id!, packetData.name!)
                    packets.push(packet)
                });
                return packets
            }
        } catch (e) {
            let error: string = ""
            if (axios.isAxiosError(e)) {
                error = e.response?.data.message
            }

            return Error("error authorization")
        }

        return Error("error authorization")
    }
}