import { combineReducers } from "redux";
import loginReducer from './loginReducer';
import userReducer from './user';
import leadReducer from './lead';
import causeReducer from './cause';
import amoFileReducer from './amofile';

const reducers = combineReducers({
    login: loginReducer,
    user: userReducer,
    lead: leadReducer,
    cause: causeReducer,
    amofile: amoFileReducer,
})

export default reducers;
export type RootState = ReturnType<typeof reducers>;