import { Dispatch } from "react"
import { LeadActionTypes } from "../action-types";
import { LeadActions } from "../actions"
import { Lead } from "../../domain/lead/lead";

export const SendLeadListRequest = () => {
    return async (dispatch: Dispatch<LeadActions>) => {
        dispatch({
            type: LeadActionTypes.LEAD_REQUEST_SEND,
          });
    }
}

export const LeadListSuccess = (res:{count: number, list: Lead[]}) => {
    return async (dispatch: Dispatch<LeadActions>) => {
        dispatch({
            type: LeadActionTypes.LEAD_SUCCESS,
            payload: res,
        });
    }
}

export const LeadListError = (error:string) => {
    return async (dispatch: Dispatch<LeadActions>) => {
        dispatch({
            type: LeadActionTypes.LEAD_ERROR,
            payload: error,
        });
    }
}

export const LeadCreateRequest = () => {
    return async (dispatch: Dispatch<LeadActions>) => {
        dispatch({
            type: LeadActionTypes.LEAD_CREATE_REQUEST,
          });
    }
}

export const LeadCreateSuccess = (lead: Lead) => {
    return async (dispatch: Dispatch<LeadActions>) => {
        dispatch({
            type: LeadActionTypes.LEAD_CREATE_SUCCESS,
            payload: lead,
          });
    }
}

export const LeadCreateError = (message: string) => {
    return async (dispatch: Dispatch<LeadActions>) => {
        dispatch({
            type: LeadActionTypes.LEAD_CREATE_ERROR,
            payload: message,
          });
    }
}


export const LeadUpdateRequest = () => {
    return async (dispatch: Dispatch<LeadActions>) => {
        dispatch({
            type: LeadActionTypes.LEAD_UPDATE_REQUEST,
          });
    }
}

export const LeadUpdateSuccess = (category: Lead) => {
    return async (dispatch: Dispatch<LeadActions>) => {
        dispatch({
            type: LeadActionTypes.LEAD_UPDATE_SUCCESS,
            payload: category,
          });
    }
}

export const LeadUpdateError = (message: string) => {
    return async (dispatch: Dispatch<LeadActions>) => {
        dispatch({
            type: LeadActionTypes.LEAD_UPDATE_ERROR,
            payload: message,
          });
    }
}


export const LeadDeleteRequest = () => {
    return async (dispatch: Dispatch<LeadActions>) => {
        dispatch({
            type: LeadActionTypes.LEAD_DELETE_REQUEST,
          });
    }
}

export const LeadDeleteSuccess = (id: number) => {
    return async (dispatch: Dispatch<LeadActions>) => {
        dispatch({
            type: LeadActionTypes.LEAD_DELETE_SUCCESS,
            payload: id,
          });
    }
}

export const LeadDeleteError = (message: string) => {
    return async (dispatch: Dispatch<LeadActions>) => {
        dispatch({
            type: LeadActionTypes.LEAD_DELETE_ERROR,
            payload: message,
          });
    }
}