import { useContext, useEffect, useState } from "react"
import { UseCasesContext } from "../../../../context/useCases"
import { useTypedSelector } from "../../../../hooks/useTypedSelector"
import { Link, useParams } from "react-router-dom"
import { TableRow } from "./tableRow"
import { Menu } from "../../../components/menu"
import { Packet } from "../../../../domain/packet/packet"

export const CauseList = () => {
    let { id } = useParams();
    const [packets, setPacketsList] = useState<Packet[]>([])

    const [form, setForm] = useState<{from: Date, to: Date}>({
        from: new Date(),
        to: new Date(),
    })

    useEffect(()=>{
        let start = new Date();
        let end = new Date();
        start.setDate(start.getDate() - 30);
        end.setDate(end.getDate() + 1);
        setForm({
            ...form,
            from: start,
            to: end, 
        })
    },[])
    let useCases = useContext(UseCasesContext)

    const causes = useTypedSelector(({ cause }) => {
        return cause
    })

    const readPacketList = async () => {
        let packets = await useCases?.leadUseCase.ReadPacketList()
        if (packets instanceof Error) {

        } else {
            setPacketsList(packets!)
        }
    }


    useEffect(()=>{
        useCases?.causeUseCase.ReadCauses()
        readPacketList()
    },[])

    return (
        <div className="layout-page" >
            <Menu />
            <div className="content-wrapper" >
                <div className="container-xxl flex-grow-1 container-p-y">
                    <div className="row mx-1">
                        <div className="col-sm-12 col-md-4">
                            <h4 className="fw-bold py-3 mb-4"><span className="text-muted fw-light">Список причин модерации /</span> Причины модерации</h4>
                        </div>
                        <div className="col-sm-12 col-md-8">
                            <div className="dt-action-buttons text-xl-end text-lg-start text-md-end text-start d-flex align-items-center justify-content-md-end justify-content-center flex-wrap me-1">
                                <div className="dt-buttons py-3 mb-4">
                                    <Link to={"/cause/add/"} className="dt-button add-new btn btn-primary mb-3 mb-md-0" ><span>Добавить</span></Link>
                                </div>
                            </div>
                        </div>

                        <div className="card">
                            <div className="table-responsive text-nowrap">
                                <table className="table mt-1" style={{tableLayout: "fixed"}}>
                                    <thead>
                                        <tr>
                                            <th style={{width: "45%"}}>Пакеты</th>
                                            <th style={{width: "45%"}}>Причины</th>
                                            <th style={{width: 20}}>Действия</th>
                                        </tr>
                                    </thead>
                                    <tbody className="table table-hover">
                                        {
                                            causes?.causes && causes.causes.map((cause, index)=>{
                                                return <TableRow cause={cause} packets={packets} last={causes?.causes!.length - 1 == index?true:false}/>
                                            })
                                        }
                                    </tbody>
                                </table>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
        </div>
    )

}