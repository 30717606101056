import { Dispatch } from "react"
import { AmoFileActionTypes } from "../action-types";
import { AmoFileActions } from "../actions"
import { AmoFile } from "../../domain/amofile/amofile";

export const SendAmoFileListRequest = () => {
    return async (dispatch: Dispatch<AmoFileActions>) => {
        dispatch({
            type: AmoFileActionTypes.AMOFILE_REQUEST_SEND,
          });
    }
}

export const AmoFileListSuccess = (res:{count: number, list: AmoFile[]}) => {
    return async (dispatch: Dispatch<AmoFileActions>) => {
        dispatch({
            type: AmoFileActionTypes.AMOFILE_SUCCESS,
            payload: res,
        });
    }
}

export const AmoFileListError = (error:string) => {
    return async (dispatch: Dispatch<AmoFileActions>) => {
        dispatch({
            type: AmoFileActionTypes.AMOFILE_ERROR,
            payload: error,
        });
    }
}

export const AmoFileCreateRequest = () => {
    return async (dispatch: Dispatch<AmoFileActions>) => {
        dispatch({
            type: AmoFileActionTypes.AMOFILE_CREATE_REQUEST,
          });
    }
}

export const AmoFileCreateSuccess = (amofile: AmoFile[]) => {
    return async (dispatch: Dispatch<AmoFileActions>) => {
        dispatch({
            type: AmoFileActionTypes.AMOFILE_CREATE_SUCCESS,
            payload: amofile,
          });
    }
}

export const AmoFileCreateError = (message: string) => {
    return async (dispatch: Dispatch<AmoFileActions>) => {
        dispatch({
            type: AmoFileActionTypes.AMOFILE_CREATE_ERROR,
            payload: message,
          });
    }
}


export const AmoFileUpdateRequest = () => {
    return async (dispatch: Dispatch<AmoFileActions>) => {
        dispatch({
            type: AmoFileActionTypes.AMOFILE_UPDATE_REQUEST,
          });
    }
}

export const AmoFileUpdateSuccess = (category: AmoFile) => {
    return async (dispatch: Dispatch<AmoFileActions>) => {
        dispatch({
            type: AmoFileActionTypes.AMOFILE_UPDATE_SUCCESS,
            payload: category,
          });
    }
}

export const AmoFileUpdateError = (message: string) => {
    return async (dispatch: Dispatch<AmoFileActions>) => {
        dispatch({
            type: AmoFileActionTypes.AMOFILE_UPDATE_ERROR,
            payload: message,
          });
    }
}


export const AmoFileDeleteRequest = () => {
    return async (dispatch: Dispatch<AmoFileActions>) => {
        dispatch({
            type: AmoFileActionTypes.AMOFILE_DELETE_REQUEST,
          });
    }
}

export const AmoFileDeleteSuccess = (id: number) => {
    return async (dispatch: Dispatch<AmoFileActions>) => {
        dispatch({
            type: AmoFileActionTypes.AMOFILE_DELETE_SUCCESS,
            payload: id,
          });
    }
}

export const AmoFileDeleteError = (message: string) => {
    return async (dispatch: Dispatch<AmoFileActions>) => {
        dispatch({
            type: AmoFileActionTypes.AMOFILE_DELETE_ERROR,
            payload: message,
          });
    }
}