import { useContext, useEffect, useState } from "react";
import { DotsVertical, Edit, Trash } from 'tabler-icons-react'
import { UseCasesContext } from "../../../../context/useCases";
import { useParams } from "react-router-dom";
import { AmoFile } from "../../../../domain/amofile/amofile";

export const TableRow = (props: { amofile: AmoFile, checked: boolean, setChecked: ()=>void, last: boolean }) => {
    const { id } = useParams()
    const [showMenu, setShowMenu] = useState(false)

    const formatDate = (day: Date) => {
        if (day.getFullYear() === 1) {
            return ""
        }
        const yyyy = day.getFullYear();
        let mm = (day.getMonth() + 1).toString(); // Months start at 0!
        let dd = day.getDate().toString();

        if (dd.length == 1) dd = '0' + dd;
        if (mm.length == 1) mm = '0' + mm;

        return dd + '.' + mm + '.' + yyyy.toString().substring(2);
    }
    let useCases = useContext(UseCasesContext)



    return (<tr>
        <td>
            <div className="form-check mt-3">
                <input className="form-check-input" type="checkbox" value="" id={"check_" + props.amofile.Id()} checked={props.checked} onClick={()=>{props.setChecked()}} />
                <label className="form-check-label" htmlFor={"check_" + props.amofile.Id()}> {props.amofile.Id()}</label>
            </div>
        </td>
        <td><a target="_blank" href={"https://moderate.leadactiv.ru/api/amofile/" + props.amofile.Id()} >{props.amofile.File()}</a></td>
        <td><a target="_blank" href={"https://novostroykigoroda.amocrm.ru/leads/detail/" + props.amofile.LeadId()} >{props.amofile.LeadId()}</a></td>
        <td>{formatDate(props.amofile.CreateAt())}</td>
        <td>
            <div className="dropdown">
                <button type="button" onClick={() => { setShowMenu(!showMenu) }} className="btn p-0 dropdown-toggle hide-arrow" data-bs-toggle="dropdown" aria-expanded="false">
                    <DotsVertical />
                </button>
                <div className={showMenu ? "dropdown-menu show" : "dropdown-menu "} style={showMenu ? props.last ? { position: "absolute", inset: "auto auto 0px -20%", margin: "0px", transform: "translate3d(0px, -20.5px, 0px)" } : { position: "absolute", marginLeft: "-40%", } : { display: "none" }}>
                    {useCases?.authUseCase.IsAdminUser()?<a className="dropdown-item d-flex" href="#" onClick={(e) => { e.preventDefault(); useCases?.amofileUseCase.DeleteAmofile(props.amofile.Id()); setShowMenu(false) }}><Trash className="me-1" size={20} /> Удалить</a>:<></>}
                </div>
            </div>
        </td>
    </tr>)
}
