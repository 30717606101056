export class Client {
     
    private id: number; 
    private name: string; 

    constructor( id: number, name: string) {
        this.id = id;
        this.name = name;
        
    }

    
    public Id = (): number => {
        return this.id
    }
    public Name = (): string => {
        return this.name
    }
   
}