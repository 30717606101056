import { Lead } from "../../domain/lead/lead";
import { LeadActionTypes } from "../action-types";
import { LeadActions } from "../actions";
import produce from 'immer';


interface LeadState {
    loading: boolean | null;
    count: number,
    leads: Lead[] | null,
    error: string | null,
    entityLoading: boolean,
    entityError: string | null,
}

const initialState: LeadState = {
    loading: null,
    count: 0,
    leads: null,
    error: null,
    entityLoading: false,
    entityError: null,
}



const reducer = produce((state: LeadState = initialState, action: LeadActions) => {
    switch (action.type) {
        case LeadActionTypes.LEAD_REQUEST_SEND:
            state.loading = true;
            return state;
        case LeadActionTypes.LEAD_SUCCESS:
            state.loading = false;
            state.count = action.payload.count;
            state.leads = action.payload.list;
            return state;
        case LeadActionTypes.LEAD_ERROR:
            state.loading = false;
            state.error = action.payload;
            return state;
        case LeadActionTypes.LEAD_CREATE_REQUEST:
            state.entityLoading = true
            state.entityError = ""
            return state;
        case LeadActionTypes.LEAD_CREATE_SUCCESS:
            state.entityLoading = false
            state.leads!.push(action.payload)
            state.entityError = ""
            return state;
        case LeadActionTypes.LEAD_UPDATE_SUCCESS:
            let newLeads: Lead[] = []

            state.leads?.forEach((lead)=>{
                if (lead.Id() == action.payload.Id()) {
                    newLeads.push(action.payload)
                } else {
                    newLeads.push(lead)
                }
            })
            
            state.leads = newLeads!
            return state;
        case LeadActionTypes.LEAD_CREATE_ERROR:
            state.entityLoading = false
            state.entityError = action.payload;
            return state;
        case LeadActionTypes.LEAD_DELETE_SUCCESS:
            state.leads = state.leads!.filter((leads) => leads.Id() !== action.payload)
            return state;
        default:
            return state;
    }
})

export default reducer;
