import axios from "axios";
import { Lead } from "../../../domain/lead/lead";
//import { LeadInterface } from "../../../useCase/lead/adapters/Interface";
import { Configuration, LeadsApi, } from "../generated";
import { LeadsInterface } from "../../../useCase/leads/adapters/interface";
import { ToDecision } from "../../../domain/lead/leadEnums";

export class LeadsRepository implements LeadsInterface {
    private service: LeadsApi;

    constructor() {
        this.service = new LeadsApi(new Configuration(), process.env.REACT_APP_BACKEND_URL)
    }

    async ReadLeads(clientId: number, managerId: number, status: string, cityId: number, packet: string, transferDateFrom: Date, transferDateTo: Date, offset: number, limit: number, sorting: string): Promise<{ count: number, list: Lead[] } | Error> {
        if (sorting[sorting.length - 1] == ";") {
            sorting = sorting.substring(0,sorting.length-1)
        }
        try {
            let response = await this.service.apiLeadGet(clientId, managerId, status, cityId, packet, formatDate(transferDateFrom), formatDate(transferDateTo), offset, limit, sorting, { headers: { "Authorization": localStorage.getItem("token") } })

            if (response.status === 200) {
                let leads: Lead[] = []
                response.data.list!.forEach(leadData => {
                    let createdAt = new Date(leadData.createdAt!)
                    let modifiedAt = new Date(leadData.modifiedAt!)

                    let lead = new Lead(leadData.id!, leadData.clientId!, leadData.clientName!, leadData.managerId!, leadData.status!, new Date(leadData.transferDate!), leadData.cityName!, leadData.cityId!, leadData.packet!, leadData.price!, leadData.clientReason!, new Date(leadData.moderateAddDate!), leadData.moderatorId!, ToDecision(leadData.decision!), new Date(leadData.moderatedAt!), leadData.comment!, leadData.cause!, new Date(leadData.actionDate!), leadData.clientLeadsGrowth!, leadData.processedToAmo!, createdAt, modifiedAt)
                    leads.push(lead)
                });
                return { count: response.data.count!, list: leads }
            }
        } catch (e) {
            let error: string = ""
            if (axios.isAxiosError(e)) {
                error = e.response?.data.message
            }

            return Error("error authorization")
        }

        return Error("error authorization")
    }

    async ExportLeads(clientId: number, managerId: number, status: string, cityId: number, packet: string, transferDateFrom: Date, transferDateTo: Date, sorting: string): Promise<string | Error> {
        if (sorting[sorting.length - 1] == ";") {
            sorting = sorting.substring(0,sorting.length-1)
        }
        try {
            let response = await this.service.apiLeadCsvGet(clientId, managerId, status, cityId, packet, formatDate(transferDateFrom), formatDate(transferDateTo), sorting, { headers: { "Authorization": localStorage.getItem("token") } })
            if (response.status === 200) {
            
                return response.data.url!
            }
        } catch (e) {
            let error: string = ""
            if (axios.isAxiosError(e)) {
                error = e.response?.data.message
            }

            return Error("error authorization")
        }

        return Error("error authorization")
    }

    async ReadLeadById(id: number): Promise<Lead | Error> {
        try {
            let response = await this.service.apiLeadIdGet(id.toString(), { headers: { "Authorization": localStorage.getItem("token") } })

            if (response.status === 200) {
                let createdAt = new Date(response.data.createdAt!)
                let modifiedAt = new Date(response.data.modifiedAt!)

                let lead = new Lead(response.data.id!, response.data.clientId!, response.data.clientName!, response.data.managerId!, response.data.status!, new Date(response.data.transferDate!), response.data.cityName!, response.data.cityId!, response.data.packet!, response.data.price!, response.data.clientReason!, new Date(response.data.moderateAddDate!), response.data.moderatorId!, ToDecision(response.data.decision!), new Date(response.data.moderatedAt!), response.data.comment!, response.data.cause!, new Date(response.data.actionDate!), response.data.clientLeadsGrowth!, response.data.processedToAmo!, createdAt, modifiedAt)

                return lead
            }
        } catch (e) {
            let error: string = ""
            if (axios.isAxiosError(e)) {
                error = e.response?.data.message
            }

            return Error("error authorization")
        }

        return Error("error authorization")
    }

    async ReadLeadStatusList(): Promise<{ status: string, description: string }[] | Error> {
        try {
            let response = await this.service.apiLeadStatusGet({ headers: { "Authorization": localStorage.getItem("token") } })

            if (response.status === 200) {
                let statuses: { status: string, description: string }[] = []
                response.data.forEach(item => {
                    statuses.push({ status: item.status!, description: item.description! })
                });
                return statuses
            }
        } catch (e) {
            let error: string = ""
            if (axios.isAxiosError(e)) {
                error = e.response?.data.message
            }

            return Error("error authorization")
        }

        return Error("error authorization")
    }

    async SetModeratorForLead(leadId: number, moderatorId: number): Promise<Lead | Error> {
        try {
            let response = await this.service.apiLeadModeratorIdPut(leadId.toString(), {moderator_id:moderatorId}, { headers: { "Authorization": localStorage.getItem("token") } })

            if (response.status === 200) {
                let createdAt = new Date(response.data.createdAt!)
                let modifiedAt = new Date(response.data.modifiedAt!)
                let lead = new Lead(response.data.id!, response.data.clientId!, response.data.clientName!, response.data.managerId!, response.data.status!, new Date(response.data.transferDate!), response.data.cityName!, response.data.cityId!, response.data.packet!, response.data.price!, response.data.clientReason!, new Date(response.data.moderateAddDate!), response.data.moderatorId!, ToDecision(response.data.decision!), new Date(response.data.moderatedAt!), response.data.comment!, response.data.cause!, new Date(response.data.actionDate!), response.data.clientLeadsGrowth!, response.data.processedToAmo!, createdAt, modifiedAt)

                return lead
            }
        } catch (e) {
            let error: string = ""
            if (axios.isAxiosError(e)) {
                error = e.response?.data.message
            }

            return Error("error authorization")
        }

        return Error("error authorization")
    }

    async ModeratorDecision(leadId: number, decision: string, cause: string, comment: string): Promise<Lead | Error> {
        try {
            let response = await this.service.apiLeadDecisionIdPut(leadId.toString(), { decision: decision, cause: cause, comment: comment }, { headers: { "Authorization": localStorage.getItem("token") } })

            if (response.status === 200) {
                let createdAt = new Date(response.data.createdAt!)
                let modifiedAt = new Date(response.data.modifiedAt!)
                let lead = new Lead(response.data.id!, response.data.clientId!, response.data.clientName!, response.data.managerId!, response.data.status!, new Date(response.data.transferDate!), response.data.cityName!, response.data.cityId!, response.data.packet!, response.data.price!, response.data.clientReason!, new Date(response.data.moderateAddDate!), response.data.moderatorId!, ToDecision(response.data.decision!), new Date(response.data.moderatedAt!), response.data.comment!, response.data.cause!, new Date(response.data.actionDate!), response.data.clientLeadsGrowth!, response.data.processedToAmo!, createdAt, modifiedAt)

                return lead
            }
        } catch (e) {
            let error: string = ""
            if (axios.isAxiosError(e)) {
                error = e.response?.data.message
            }

            return Error("error authorization")
        }

        return Error("error authorization")
    }

    async FinalizeModeration(leadId: number): Promise<Lead | Error> {
        try {
            let response = await this.service.apiLeadFinalizeIdPut(leadId.toString(), { headers: { "Authorization": localStorage.getItem("token") } })

            if (response.status === 200) {
                let createdAt = new Date(response.data.createdAt!)
                let modifiedAt = new Date(response.data.modifiedAt!)
                let lead = new Lead(response.data.id!, response.data.clientId!, response.data.clientName!, response.data.managerId!, response.data.status!, new Date(response.data.transferDate!), response.data.cityName!, response.data.cityId!, response.data.packet!, response.data.price!, response.data.clientReason!, new Date(response.data.moderateAddDate!), response.data.moderatorId!, ToDecision(response.data.decision!), new Date(response.data.moderatedAt!), response.data.comment!, response.data.cause!, new Date(response.data.actionDate!), response.data.clientLeadsGrowth!, response.data.processedToAmo!, createdAt, modifiedAt)

                return lead
            }
        } catch (e) {
            let error: string = ""
            if (axios.isAxiosError(e)) {
                error = e.response?.data.message
            }

            return Error("error authorization")
        }

        return Error("error authorization")
    }

    async RejectLead(leadId: number): Promise<Lead | Error> {
        try {
            let response = await this.service.apiLeadRejectIdPut(leadId.toString(), { headers: { "Authorization": localStorage.getItem("token") } })

            if (response.status === 200) {
                let createdAt = new Date(response.data.createdAt!)
                let modifiedAt = new Date(response.data.modifiedAt!)
                let lead = new Lead(response.data.id!, response.data.clientId!, response.data.clientName!, response.data.managerId!, response.data.status!, new Date(response.data.transferDate!), response.data.cityName!, response.data.cityId!, response.data.packet!, response.data.price!, response.data.clientReason!, new Date(response.data.moderateAddDate!), response.data.moderatorId!, ToDecision(response.data.decision!), new Date(response.data.moderatedAt!), response.data.comment!, response.data.cause!, new Date(response.data.actionDate!), response.data.clientLeadsGrowth!, response.data.processedToAmo!, createdAt, modifiedAt)

                return lead
            }
        } catch (e) {
            let error: string = ""
            if (axios.isAxiosError(e)) {
                error = e.response?.data.message
            }

            return Error("error authorization")
        }

        return Error("error authorization")
    }

    async ExchangeOnZero(leadId: number): Promise<Lead | Error> {
        try {
            let response = await this.service.apiLeadExchangeOnZeroIdPut(leadId.toString(), { headers: { "Authorization": localStorage.getItem("token") } })

            if (response.status === 200) {
                let createdAt = new Date(response.data.createdAt!)
                let modifiedAt = new Date(response.data.modifiedAt!)
                let lead = new Lead(response.data.id!, response.data.clientId!, response.data.clientName!, response.data.managerId!, response.data.status!, new Date(response.data.transferDate!), response.data.cityName!, response.data.cityId!, response.data.packet!, response.data.price!, response.data.clientReason!, new Date(response.data.moderateAddDate!), response.data.moderatorId!, ToDecision(response.data.decision!), new Date(response.data.moderatedAt!), response.data.comment!, response.data.cause!, new Date(response.data.actionDate!), response.data.clientLeadsGrowth!, response.data.processedToAmo!, createdAt, modifiedAt)

                return lead
            }
        } catch (e) {
            let error: string = ""
            if (axios.isAxiosError(e)) {
                error = e.response?.data.message
            }

            return Error("error authorization")
        }

        return Error("error authorization")
    }

    async AnnulLead(leadId: number): Promise<Lead | Error> {
        try {
            let response = await this.service.apiLeadAnnulIdPut(leadId.toString(), { headers: { "Authorization": localStorage.getItem("token") } })

            if (response.status === 200) {
                let createdAt = new Date(response.data.createdAt!)
                let modifiedAt = new Date(response.data.modifiedAt!)
                let lead = new Lead(response.data.id!, response.data.clientId!, response.data.clientName!, response.data.managerId!, response.data.status!, new Date(response.data.transferDate!), response.data.cityName!, response.data.cityId!, response.data.packet!, response.data.price!, response.data.clientReason!, new Date(response.data.moderateAddDate!), response.data.moderatorId!, ToDecision(response.data.decision!), new Date(response.data.moderatedAt!), response.data.comment!, response.data.cause!, new Date(response.data.actionDate!), response.data.clientLeadsGrowth!, response.data.processedToAmo!, createdAt, modifiedAt)

                return lead
            }
        } catch (e) {
            let error: string = ""
            if (axios.isAxiosError(e)) {
                error = e.response?.data.message
            }

            return Error("error authorization")
        }

        return Error("error authorization")
    }

    async SetClientCause(leadId: number, cause: string): Promise<Lead | Error> {
        try {
            let response = await this.service.apiLeadClientCauseIdPut(leadId.toString(), {cause: cause}, { headers: { "Authorization": localStorage.getItem("token") } })

            if (response.status === 200) {
                let createdAt = new Date(response.data.createdAt!)
                let modifiedAt = new Date(response.data.modifiedAt!)
                let lead = new Lead(response.data.id!, response.data.clientId!, response.data.clientName!, response.data.managerId!, response.data.status!, new Date(response.data.transferDate!), response.data.cityName!, response.data.cityId!, response.data.packet!, response.data.price!, response.data.clientReason!, new Date(response.data.moderateAddDate!), response.data.moderatorId!, ToDecision(response.data.decision!), new Date(response.data.moderatedAt!), response.data.comment!, response.data.cause!, new Date(response.data.actionDate!), response.data.clientLeadsGrowth!, response.data.processedToAmo!, createdAt, modifiedAt)

                return lead
            }
        } catch (e) {
            let error: string = ""
            if (axios.isAxiosError(e)) {
                error = e.response?.data.message
            }

            return Error("error authorization")
        }

        return Error("error authorization")
    }
}

const formatDate = (day: Date) => {
    if (day.getFullYear() === 1) {
        return ""
    }
    const yyyy = day.getFullYear();
    let mm = (day.getMonth() + 1).toString(); // Months start at 0!
    let dd = day.getDate().toString();

    if (dd.length == 1) dd = '0' + dd;
    if (mm.length == 1) mm = '0' + mm;

    return dd + '.' + mm + '.' + yyyy.toString();
}