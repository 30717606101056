import axios from "axios";

import { Configuration, CityApi, } from "../generated";
import { City } from "../../../domain/city/city";

export class CitysRepository {
    private service: CityApi;

    constructor() {
        this.service = new CityApi(new Configuration(), process.env.REACT_APP_BACKEND_URL)
    }
    
    async ReadCitys(): Promise<City[] | Error> {
        try {
            let response = await this.service.apiCityGet({ headers: { "Authorization": localStorage.getItem("token") } })

            if (response.status === 200) {
                let citys: City[] = []
                response.data!.list.forEach(cityData => {
                    let city = new City(cityData.id!, cityData.name!)
                    citys.push(city)
                });
                return citys
            }
        } catch (e) {
            let error: string = ""
            if (axios.isAxiosError(e)) {
                error = e.response?.data.message
            }

            return Error("error authorization")
        }

        return Error("error authorization")
    }
}

const formatDate = (day: Date) => {
    if (day.getFullYear() === 1) {
        return ""
    }
    const yyyy = day.getFullYear();
    let mm = (day.getMonth() + 1).toString(); // Months start at 0!
    let dd = day.getDate().toString();

    if (dd.length == 1) dd = '0' + dd;
    if (mm.length == 1) mm = '0' + mm;

    return dd + '.' + mm + '.' + yyyy.toString();
}