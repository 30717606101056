export class Cause {
     
    private id: number;
    private packetIds: number[];
    private items: string[];

    constructor( id: number, packetIds: number[], items: string[]) {
        this.id = id;
        this.packetIds = packetIds;
        this.items = items;
        
    }

    
    public Id = (): number => {
        return this.id
    }

    public PacketIds = (): number[] => {
        return this.packetIds
    }

    public Items = (): string[] => {
        return this.items
    }
}